import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "RobinHood Bingo",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:39:02.000Z",
  "url": "/2018/02/robinhood-bingo/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Client:Netboostmedia`}</p>
    <p>{`Date:09/2009`}</p>
    <p>{`Launch Project`}</p>
    <p>{`This website was build from the ground up, it started by getting the design after several and several revisions.`}</p>
    <p>{`Base template was created using an outsource from elance, the rest of the pages were built by our team.`}</p>
    <p>{`All server side coding was with cooperation with 888, for displaying, purchases, games played, wins, promotions and more.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      